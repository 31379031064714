<template>
  <div class="container mt-3">
    <div class="d-flex">
      <button class="btn btn-dark mb-3 px-4 me-4" @click.prevent="state.newTicket = true">
        Создать заявку
      </button>
      <div class="input-group w-25 mb-auto">
        <p-icon class="input-group-text" name="search"/>
        <input type="text" class="form-control" placeholder="Поиск">
      </div>
    </div>
    <p-table th-class="w-25 text-nowrap" :data="[{}]" :columns="ticketsColumns">
      <template v-slot:col-wait>
        <div class="d-flex flex-column">
          <router-link
            v-for="ticket in state.tickets.wait"
            :key="ticket.id"
            :to="{ name: 'ticket', params: { id: ticket.id } }"
          >
            <p-ticket-card :ticket="ticket" class="mb-3 w-auto w-100"/>
          </router-link>
        </div>
      </template>
      <template v-slot:col-todo>
        <div class="d-flex flex-column">
          <router-link
            v-for="ticket in state.tickets.todo"
            :key="ticket.id"
            :to="{ name: 'ticket', params: { id: ticket.id } }"
          >
            <p-ticket-card :ticket="ticket" class="mb-3 w-auto w-100"/>
          </router-link>
        </div>
      </template>
      <template v-slot:col-in_progress>
        <div class="d-flex flex-column">
          <router-link
            v-for="ticket in state.tickets.in_progress"
            :key="ticket.id"
            :to="{ name: 'ticket', params: { id: ticket.id } }"
          >
            <p-ticket-card :ticket="ticket" class="mb-3 w-auto w-100"/>
          </router-link>
        </div>
      </template>
      <template v-slot:col-lab_review>
        <div class="d-flex flex-column">
          <router-link
            v-for="ticket in state.tickets.lab_review"
            :key="ticket.id"
            :to="{ name: 'ticket', params: { id: ticket.id } }"
          >
            <p-ticket-card :ticket="ticket" class="mb-3 w-auto w-100"/>
          </router-link>
        </div>
      </template>
      <template v-slot:col-manager_review>
        <div class="d-flex flex-column">
          <router-link
            v-for="ticket in state.tickets.manager_review"
            :key="ticket.id"
            :to="{ name: 'ticket', params: { id: ticket.id } }"
          >
            <p-ticket-card :ticket="ticket" class="mb-3 w-auto w-100"/>
          </router-link>
        </div>
      </template>
    </p-table>
    <slideout class="w-100" :size="800" v-model="state.newTicket" title="Новая заявка">
      <p-ticker-form class="px-5 py-3" @created="ticketCreated" :worker-required="false"/>
    </slideout>
  </div>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import PTickerForm from '@/components/forms/Ticket'
import PTable from '@/components/ui/Table'
import PTicketCard from '@/components/ui/TicketCard'
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()
const state = reactive({
  newTicket: false,
  tickets: {},
})

const ticketsColumns = [
  {
    name: 'Ожидание',
    key: 'wait',
  },
  {
    name: 'Новые',
    key: 'todo',
  },
  {
    name: 'Принята исполнителем',
    key: 'in_progress',
  },
  {
    name: 'Поверка выполнена',
    key: 'lab_review',
  },
  {
    name: 'Отправлены в УК',
    key: 'manager_review',
  },
]

const ticketCreated = async () => {
  state.newTicket = false
  await load()
}

const load = async () => {
  const tickets = await api.post(urls.TICKETS.LIST, ticketsColumns.map(col => col.key))
  state.tickets = Object.fromEntries(new Map(
    ticketsColumns.map(col => [col.key, tickets.filter(t => t.status === col.key)])
  ))
}

onMounted(load)
</script>

<style scoped>

</style>
