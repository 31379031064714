<template>
  <div class="d-flex w-100">
    <div class="card bg-light shadow-sm w-100">
      <div class="card-body">
        <p class="card-text mb-0">
          {{ ticket.home.city }}, {{ ticket.home.street }} {{ ticket.home.streetNumber }}, кв. {{ ticket.apt }}
        </p>

        <p class="fs-6 fw-light mb-0">
          {{ ticket.lastName }} {{ ticket.firstName }} {{ ticket.middleName }}
          <br>
          {{ ticket.phone }}
          <br>
          <br>
          {{ (new Date(ticket.date)).toLocaleDateString() }} {{ticket.timeStart}}:00 - {{ticket.timeEnd}}:00
          <br>
          {{ ticket.worker ? formatName(ticket.worker) : '' }}
          <span
            v-if="ticket.comment"
            class="lh-1"
            style="font-size: 0.82rem!important;"
            data-bs-toggle="tooltip" data-bs-placement="right" :title="ticket.comment"
          >
            <br>
            <br>
            {{ trim(ticket.comment, 75) }}
          </span>
        </p>
      </div>
    </div>
    <div class="mx-3 d-inline-block">
      <p-icon name="error" class="text-warning" data-bs-toggle="tooltip" data-bs-placement="right"
              title="Поверщик отказался от заявки"
              v-if="ticket.rejectedByWorker"
      />
      <p-icon name="error" color="warning" data-bs-toggle="tooltip" data-bs-placement="right"
              title="Ожидает назначения исполнителя"
              v-if="ticket.worker === null"
      />
      <p-tooltip
        :title="`Отклонено УК с комментарием: ${trim(ticket.managerReviewRejectReason, 75)}`"
        v-if="ticket.managerReviewRejectReason && ticket.status === 'lab_review'"
      >
        <p-icon name="error" color="danger"/>
      </p-tooltip>

    </div>
  </div>
</template>

<script setup>
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { defineProps, onMounted, onUnmounted } from 'vue'
import { formatName, trim } from '@/common'

defineProps({
  ticket: {
    type: Object,
    required: true,
  },
})

onMounted(() => {
  Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    .forEach(tooltipNode => new Tooltip(tooltipNode))
})
onUnmounted(() => {
  Array.from(document.querySelectorAll('div.tooltip')).forEach(el => el.remove())
})
</script>

<style scoped>
.card {
  border: none;
}
</style>
